<template>
  <default>
    <!-- Breadcrumbs with Flex Alignment -->
    <div class="tw-mb-4 tw-flex tw-items-center c-blue-text">
      <v-chip-group mandatory active-class="primary">
        <v-chip :to="{ name: 'Dashboard' }" class="tw-text-gray-600 hover:tw-text-blue-600 tw-font-medium">Dashboard</v-chip>
        <span class="tw-mx-2 tw-mt-2 tw-text-gray-800"> &gt; </span>
        <v-chip :to="{ name: 'Products' }" class="tw-text-blue-600 tw-font-medium">Products</v-chip>
      </v-chip-group>
    </div>

    <!-- Main Card for Product Selection -->
    <v-card elevation="2" class="tw-px-4 md:tw-px-8 tw-py-4 md:tw-py-8 tw-rounded-lg tw-shadow-md tw-mb-6">
      <card-title icon="/images/icons/top-icon.png" class="tw-text-blue-800 tw-text-lg md:tw-text-xl tw-font-bold">
        Choose which product you wish to quote for
      </card-title>

      <!-- Product Cards Grid -->
      <div class="tw-w-full tw-px-2 md:tw-px-4 tw-py-4 md:tw-py-8 tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-6">
        <v-card
          v-for="(p, i) in products"
          :key="i"
          v-show="p.display"
          class="product-card tw-flex tw-flex-col tw-items-center tw-text-center tw-p-6 tw-rounded-lg tw-shadow-sm hover:tw-shadow-lg tw-transition-all tw-duration-300"
          elevation="2"
        >
          <!-- Help Icon on Top Right -->
          <v-icon class="c-gray-text tw-absolute tw-self-end px-2 tw-cursor-pointer" size="20">mdi-help-circle</v-icon>

          <!-- Product Icon with Background Circle -->
          <div class="tw-w-16 tw-h-16 tw-rounded-full tw-bg-blue-100 tw-flex tw-items-center tw-justify-center tw-mb-4">
            <img :src="p.icon" alt="icon" class="tw-w-8 tw-h-8">
          </div>

          <!-- Product Title -->
          <h5 class="tw-text-blue-800 tw-font-semibold tw-text-lg tw-mb-2">{{ p.title }}</h5>

          <!-- Product Description -->
          <p class="c-gray-text tw-text-sm tw-mb-4">{{ p.desc }}</p>

          <!-- Get Quote Button -->
          <v-btn @click="navigateTo(p)" outlined color="primary" class="tw-font-medium tw-rounded-full tw-mt-auto tw-bg-blue-200 hover:tw-bg-blue-500 hover:tw-text-white">
            Get Quote
          </v-btn>
        </v-card>
      </div>
    </v-card>
  </default>
</template>

<script>
import Default from '../layouts/Default';
import CardTitle from '../components/CardTitle';
import { allProducts } from '@/utils/products';

export default {
  created() {
    if (typeof window !== 'undefined' && window.gtag) {
      window.gtag('event', 'products_page_visited_by_agent', {
        event_category: 'Products page component created',
        event_label: 'products component created',
      });
    }
  },
  mounted() {
    this.$store.commit('quote/setSaved', false);
  },
  name: 'Dashboard',
  metaInfo: {
    title: 'Products',
  },
  components: { CardTitle, Default },
  data() {
    return {
      products: allProducts(),
    };
  },
  methods: {
    navigateTo(product) {
      localStorage.setItem('selectedProduct', product.title);
      this.$router.push(product.link);
    },
  },
};
</script>
<style scoped>
/* Breadcrumb styling */
.tw-mb-4 .v-chip {
  font-weight: 500;
  cursor: pointer;
}

/* Product Card styling */
.product-card {
  background-color: white;
  min-height: 280px;
  border: 1px solid #e0e0e0;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
}

/* Hover effect */
.product-card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}

/* Icon container */
.tw-w-16.tw-h-16 {
  width: 4rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #ebf4ff;
}

.tw-w-8.tw-h-8 {
  width: 2rem;
  height: 2rem;
}
</style>
